import React from "react"
import { Helmet } from "react-helmet"
import HoneyguideLogo from "../../static/h-logo.svg"
import "./style/reset.css"

const Home = () => {

  const mainBox = {
    display: "flex",
    backgroundColor: "#FFCF8A",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
  };

  const brandLogo = {
    width: "50%",
  };


  return (
    <div class="main" style={mainBox}>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Honeyguide | Building technology for travel brands</title>
      </Helmet>
      <HoneyguideLogo class="brand-logo" style={brandLogo}/>
    </div>
  )
}

export default Home;
